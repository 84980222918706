/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import { format } from "date-fns";
import { BookingPayload } from "../config/marae-booking/payload";

export function transformMaraeBookingData(
    survey: Survey.SurveyModel
): BookingPayload {
    const surveyData = survey.data;
    const addressData = surveyData.addressfinder;
    const addressLines = [
        addressData.address_line_1,
        addressData.address_line_2,
        addressData.suburb,
        addressData.city,
        addressData.postcode,
        addressData.country,
    ];
    const address = `   ${addressLines.filter((rec) => rec).join("\n   ")}`;

    const guests = `
        Kaumatua ${surveyData.kaumatua || 0}
        Adults ${surveyData.adults || 0}
        Tamariki ${surveyData.tamariki || 0}`;
    const message = `Organisation: ${surveyData.organisation || ""}
Address:
${address}
Purpose of Booking: ${surveyData.bookingPurpose}
Arrival Date: ${format(new Date(surveyData.arrivalDate), "dd/MMM/yyyy")}
Arrival Time: ${surveyData.arrivalTime}
Depart Date: ${format(new Date(surveyData.departDate), "dd/MMM/yyyy")}
Depart Time: ${surveyData.departTime}
Number of Guests: ${guests}
Required Facilities: ${surveyData.facilities}
Required Services: ${surveyData.services}
Message: ${surveyData.message || ""}`;
    return {
        name: surveyData.contactName,
        phone: surveyData.phone || "",
        email: surveyData.email,
        message,
        recaptcha: null,
    };
}
