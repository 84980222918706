/* eslint-disable max-lines */
import { ADDRESS_FINDER_KEY } from "../../components/survey-widgets/address-finder-widget";
import { DATEPICKER_KEY } from "../../components/survey-widgets/datepicker-widget";

export const createSurveyConfig = () => {
    return {
        pages: [
            {
                name: "maraeBooking",
                title: "Make a Booking",
                elements: [
                    {
                        type: "text",
                        name: "organisation",
                        title: "Organisation",
                        placeHolder: "Ingoa",
                    },
                    {
                        type: "text",
                        name: "contactName",
                        title: "Contact Name",
                        placeHolder: "Ingoa Whakapā",
                        isRequired: true,
                    },
                    {
                        title: "Phone",
                        type: "text",
                        name: "phone",
                        placeHolder: "Waea",
                    },
                    {
                        title: "Email",
                        type: "text",
                        name: "email",
                        isRequired: true,
                        validators: [
                            {
                                type: "email",
                            },
                        ],
                        startWithNewLine: false,
                        placeHolder: "Īmeera",
                    },
                ],
            },
            {
                name: "address",
                title: "Address - Wāhi Noho",
                questions: [
                    {
                        title: "Address",
                        type: ADDRESS_FINDER_KEY,
                        name: "addressfinder",
                        isRequired: true,
                    },
                ],
            },
            {
                name: "reqFacilities",
                title: "Facilities Requirements",
                questions: [
                    {
                        type: "checkbox",
                        title: "What facilities do you require?",
                        name: "facilities",
                        hasNone: true,
                        colCount: 1,
                        isRequired: true,
                        choices: [
                            "Wharenui",
                            "Whare Kai (Large/Comercial)",
                            "Whare Kai (Dining)",
                            "Whare Kai (Smaller Private Kitchen)",
                            "Whole Marae",
                        ],
                    },
                    {
                        type: "text",
                        title: "Purpose of Booking",
                        name: "bookingPurpose",
                        startWithNewLine: true,
                        isRequired: true,
                        placeHolder: "Kaupapa",
                    },
                ],
            },
            {
                name: "bookingDate",
                title: "Booking Date and Times",
                elements: [
                    {
                        type: DATEPICKER_KEY,
                        inputType: "date",
                        name: "arrivalDate",
                        title: "Arrival Date",
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "arrivalTime",
                        title: "Arrival Time",
                        startWithNewLine: false,
                        isRequired: true,
                        placeHolder: "Wā tae mai",
                    },
                    {
                        type: DATEPICKER_KEY,
                        inputType: "date",
                        name: "departDate",
                        title: "Departure Date",
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "departTime",
                        title: "Departure Time",
                        startWithNewLine: false,
                        isRequired: true,
                        placeHolder: "Wā wehe atu",
                    },
                ],
            },
            {
                name: "guests",
                title: "How many will be staying?",
                questions: [
                    {
                        type: "text",
                        name: "kaumatua",
                        title: "Kaumātua",
                        startWithNewLine: false,
                        placeHolder: "Kaumātua",
                    },
                    {
                        type: "text",
                        name: "adults",
                        title: "Adults",
                        startWithNewLine: true,
                        placeHolder: "Pakeke",
                    },
                    {
                        type: "text",
                        name: "tamariki",
                        title: "Tamariki",
                        startWithNewLine: true,
                        placeHolder: "Tamariki",
                    },
                ],
            },
            {
                name: "commentsRequests",
                title: "What services do you require?",
                elements: [
                    {
                        type: "checkbox",
                        title: "Please select the services you require?",
                        name: "services",
                        hasNone: true,
                        colCount: 1,
                        isRequired: true,
                        choices: [
                            "Pōwhiri",
                            "Kaikōrero",
                            "Kaikaranga",
                            "Mihi whakatau",
                            "Health & safety brief",
                        ],
                    },
                ],
            },
            {
                name: "commentsRequests",
                title: "Further comments or requests",
                elements: [
                    {
                        type: "comment",
                        name: "message",
                        title: "Please leave your message here",
                        placeHolder: "Kōrero anō",
                    },
                ],
            },
        ],
    };
};
