/* eslint-disable @typescript-eslint/no-explicit-any */
import { BANK_ACCOUNT_KEY } from "../../components/survey-widgets/nz-bank-account-widget";
import { ADDRESS_FINDER_KEY } from "../../components/survey-widgets/address-finder-widget";
import { DATEPICKER_KEY } from "../../components/survey-widgets/datepicker-widget";

const createChildPage = (childNumber: number) => {
    const ret = {
        name: `child${childNumber}Details`,
        title: `Child ${childNumber} Details - Tamaiti ${childNumber}`,
        visibleIf: `{child${childNumber - 1}Name} != '' && {child${
            childNumber - 1
        }DOB} != '' && {child${childNumber - 1}School} != ''`,
        questions: [
            {
                type: "text",
                name: `child${childNumber}Name`,
                title: "Name",
                placeHolder: "Ingoa",
                startWithNewLine: true,
            },
            {
                type: DATEPICKER_KEY,
                inputType: "date",
                name: `child${childNumber}DOB`,
                title: "Date of Birth",
            },
            {
                type: "text",
                name: `child${childNumber}ID`,
                title: "Ngāti Kuia ID",
                placeHolder: "Nama Tūmau",
                startWithNewLine: false,
            },
            {
                type: "text",
                name: `child${childNumber}School`,
                title: "School",
                placeHolder: "Kura",
            },
            {
                type: "text",
                name: `child${childNumber}SchoolYear`,
                title: "School Year",
                placeHolder: "Tau Kura",
                startWithNewLine: false,
            },
        ],
    };

    return ret;
};

export const createSurveyConfig = () => {
    const pages: any[] = [
        {
            name: "parentDetails",
            title: "Parent Details - Ngā Mātua",
            elements: [
                {
                    type: "text",
                    name: "parentName",
                    title: "Full Name",
                    placeHolder: "Ingoa",
                    isRequired: true,
                },
                {
                    type: "text",
                    title: "Phone",
                    name: "phone",
                    placeHolder: "Waea",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "email",
                    title: "Email",
                    placeHolder: "Īmēra",
                    validators: [
                        {
                            type: "regex",
                            regex: /^ *\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+ *$/,
                            text: "Please enter a valid e-mail address.",
                        },
                    ],
                    startWithNewLine: false,
                    isRequired: true,
                },
            ],
        },
        {
            name: "address",
            title: "Address - Wāhi Noho",
            questions: [
                {
                    title: "Address",
                    type: ADDRESS_FINDER_KEY,
                    name: "addressfinder",
                    isRequired: true,
                },
            ],
        },
        {
            name: "bankAccountCountry",
            title: "Bank Account - Pēke",
            questions: [
                {
                    name: "bankAccountCountry",
                    title:
                        "Do you have a New Zealand or an Overseas bank account?",
                    type: "radiogroup",
                    choices: ["Aotearoa", "Overseas"],
                    isRequired: true,
                },
            ],
        },
        {
            name: "overseasBankAccount",
            title: "Overseas Bank Account - Pēke Tāwahi",
            visibleIf: "{bankAccountCountry} = 'Overseas'",
            questions: [
                {
                    type: "text",
                    name: "overseasBankCountry",
                    title: "Country of Residence",
                    placeHolder: "Wāhi Noho",
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankSwift",
                    title: "SWIFT Code",
                    placeHolder: "Nama SWIFT",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankName",
                    title: "Account Name (must match your bank statement)",
                    placeHolder: "Ingoa Pēke",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankNumber",
                    title: "Account Number",
                    placeHolder: "Nama Pēke",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankAddress",
                    title: "Address",
                    placeHolder: "Address (must match your bank statement)",
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
        {
            name: "bankDetails",
            title: "Bank Details - Tōu Pēke",
            visibleIf: "{bankAccountCountry} = 'Aotearoa'",
            questions: [
                {
                    type: BANK_ACCOUNT_KEY,
                    name: "bank",
                    title: "Name on Bank Account",
                    startWithNewLine: false,
                    isRequired: true,
                    validators: [
                        {
                            type: "expression",
                            expression: "validBankAccount({bank}) = true",
                            text:
                                "Please enter a bank account name and a valid number",
                        },
                    ],
                },
            ],
        },
        {
            name: "child1Details",
            title: "Child Details - Tamaiti",
            questions: [
                {
                    type: "text",
                    name: "child1Name",
                    title: "Name",
                    placeHolder: "Ingoa",
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: DATEPICKER_KEY,
                    inputType: "date",
                    name: "child1DOB",
                    title: "Date of Birth",
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "child1ID",
                    title: "Ngāti Kuia ID",
                    placeHolder: "Nama Tūmau",
                    isRequired: true,
                    startWithNewLine: false,
                },
                {
                    type: "text",
                    name: "child1School",
                    title: "School",
                    placeHolder: "Kura",
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "child1SchoolYear",
                    title: "School Year",
                    placeHolder: "Tau Kura",
                    isRequired: true,
                    startWithNewLine: false,
                },
            ],
        },
    ];

    for (let i = 2; i < 20; i += 1) {
        pages.push(createChildPage(i));
    }

    pages.push({
        name: "parentDeclaration",
        title: "Parent Declaration",
        questions: [
            {
                name: "honestyAcknowledgement",
                title:
                    "By checking this box, you declare that all information provided is true and correct.",
                type: "checkbox",
                choices: ["Āe"],
                isRequired: true,
            },
        ],
    });

    return { pages };
};
