/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import * as Survey from "survey-react";
import {
    GrantPayload,
    MemberPayload,
} from "../config/tertiary-education-grant/payload";

export function transformTertiaryEducationGrantData(
    survey: Survey.SurveyModel
): GrantPayload {
    const surveyData = survey.data;
    const data: MemberPayload = {
        applicantName: surveyData.applicantName,
        dob: surveyData.dob,
        gender: surveyData.gender,
        cell: surveyData.cell,
        phone: surveyData.phone,
        email: surveyData.email,
        facebook: surveyData.facebook,

        address: {
            line1: surveyData.address.address_line_1 || "",
            line2: surveyData.address.address_line_2 || "",
            suburb: surveyData.address.suburb || "",
            city: surveyData.address.city || "",
            postcode: surveyData.address.postcode || "",
            country: surveyData.address.country || "",
        },
        studyDetails: {
            studentId: surveyData.studentId,
            institutionName: surveyData.institutionName,
            courseName: surveyData.courseName,
            courseLength: surveyData.courseLength,
            yearOfStudy: surveyData.yearOfStudy,
            institutionAddress: {
                line1: surveyData.institutionAddress.address_line_1 || "",
                line2: surveyData.institutionAddress.address_line_2 || "",
                suburb: surveyData.institutionAddress.suburb || "",
                city: surveyData.institutionAddress.city || "",
                postcode: surveyData.institutionAddress.postcode || "",
                country: surveyData.institutionAddress.country || "",
            },
        },
        aboutYou: surveyData.aboutYou,
        goals: surveyData.goals,
        enhancementOfIwi: surveyData.enhancementOfIwi,
        teReoLevel: surveyData.teReoLevel,
    };

    if (surveyData.bankAccountCountry === "Aotearoa") {
        const bankData = surveyData.bank;
        data.bankAccount = {
            accountName: bankData.name,
            accountNumber: `${bankData.bank}-${bankData.branch}-${bankData.account}-${bankData.suffix}`,
        };
    } else {
        data.overseasBankAccount = {
            country: surveyData.overseasBankCountry,
            swiftCode: surveyData.overseasBankSwift,
            accountName: surveyData.overseasBankName,
            accountNumber: surveyData.overseasBankNumber,
            accountAddress: surveyData.overseasBankAddress,
        };
    }

    return { data, recaptcha: null, tenant: "KUIA" };
}
