/* eslint-disable @typescript-eslint/no-explicit-any */
import { BANK_ACCOUNT_KEY } from "../../components/survey-widgets/nz-bank-account-widget";
import { ADDRESS_FINDER_KEY } from "../../components/survey-widgets/address-finder-widget";
import { DATEPICKER_KEY } from "../../components/survey-widgets/datepicker-widget";

export const createSurveyConfig = () => {
    const pages: any[] = [
        {
            elements: [
                {
                    type: "panel",
                    elements: [
                        {
                            type: "html",
                            name: "eligibility",
                            html:
                                "<article> <h3> Eligibility </h3> <div> <ul> \t <li>  \t\t <p> Only Ngāti Kuia whānau may apply for an Educational Grant. </p> \t </li> \t <li> \t\t <p>Grant recipients may be asked to attend and support Ngāti Kuia activities or initiatives and to provide a profile for iwi communications.</p> \t </li> \t <li> \t\t <p>You must notify Te Rūnanga o Ngāti Kuia Trust immediately if you withdraw from or fail to complete your course of study.</p> \t </li> \t <li> \t\t <p>Applicants must provide a transcript of their academic record.</p> \t </li> \t <li> \t\t <p>Provision of funding by Te Rūnanga o Ngāti Kuia does not guarantee continued funding in the future.</p> \t </li> </ul> </div> </article>",
                        },
                        {
                            name: "eligibilityAcknowledgement",
                            title:
                                "By checking this box, you have read the eligibility criteria.",
                            type: "checkbox",
                            choices: ["Āe"],
                            isRequired: true,
                        },
                    ],
                    name: "panel1",
                },
            ],
            name: "page0",
        },
        {
            elements: [
                {
                    type: "panel",
                    elements: [
                        {
                            type: "html",
                            name: "privacyAct",
                            html:
                                "<article> <h3> Privacy Act </h3> <div> <ul> \t <li>  \t\t <p> All information provided by the applicant is held by the Te Rūnanga o Ngāti Kuia Trust in accordance with the Privacy Act 1993. </p> \t </li> \t <li> \t\t <p>We do seek permission to use applicants profiles in our iwi communications and statistical information and you are asked to give this permission when accepting the declaration at the end of this application.</p> \t </li> </ul> </div> </article>",
                        },
                        {
                            name: "privacyActAcknowledgement",
                            title:
                                "By checking this box, you understand the informaton you provide is subject to the Privacy Act 1993.",
                            type: "checkbox",
                            choices: ["Āe"],
                            isRequired: true,
                        },
                    ],
                    name: "panel2",
                },
            ],
            name: "page0",
        },
        {
            name: "applicant",
            title: "Applicant Details - Kaitono",
            elements: [
                {
                    type: "text",
                    name: "applicantName",
                    title: "Full Name",
                    placeHolder: "Ingoa",
                    isRequired: true,
                },
                {
                    type: DATEPICKER_KEY,
                    inputType: "date",
                    name: "dob",
                    title: "Birthdate",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "radiogroup",
                    name: "gender",
                    title: "Gender",
                    choices: ["Wahine", "Tane", "Ira Weherua-kore"],
                    colCount: 0,
                    isRequired: true,
                    startWithNewLine: false,
                },
                {
                    type: "text",
                    title: "Cell",
                    name: "cell",
                    placeHolder: "Waea Pūkoro",
                    startWithNewLine: true,
                },
                {
                    type: "text",
                    title: "Phone",
                    name: "phone",
                    placeHolder: "Waea",
                    startWithNewLine: false,
                },
                {
                    type: "text",
                    name: "email",
                    title: "Email",
                    placeHolder: "Īmēra",
                    validators: [
                        {
                            type: "regex",
                            regex: /^ *\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+ *$/,
                            text: "Please enter a valid e-mail address.",
                        },
                    ],
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    title: "Facebook",
                    name: "facebook",
                    placeHolder: "Pukamata",
                    startWithNewLine: false,
                },
            ],
        },
        {
            name: "address",
            title: "Address - Wāhi Noho",
            questions: [
                {
                    title: "Address",
                    type: ADDRESS_FINDER_KEY,
                    name: "address",
                    isRequired: true,
                },
            ],
        },
        {
            name: "studyDetails",
            title: "Study Details - Taipitopito Ako",
            elements: [
                {
                    type: "text",
                    name: "studentId",
                    title: "Student ID",
                    placeHolder: "Nama Tauira",
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "institutionName",
                    title: "Institution Name",
                    placeHolder: "Ingoa Wānanga",
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "courseName",
                    title: "Course Name",
                    placeHolder: "Ingoa Akomanga",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "courseLength",
                    title: "Length of Course",
                    placeHolder: "Te roa o te Akomanga",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "yearOfStudy",
                    title: "Year of Study",
                    placeHolder: "Te Tau o te Ako",
                    startWithNewLine: false,
                    isRequired: true,
                },
            ],
        },
        {
            name: "institutionAddress",
            title: "Institution Address - Wānanga Wāhi Noho",
            questions: [
                {
                    title: "Institution Address",
                    type: ADDRESS_FINDER_KEY,
                    name: "institutionAddress",
                    isRequired: true,
                },
            ],
        },
        {
            name: "aboutYou",
            title: "About You - Mōu",
            elements: [
                {
                    type: "comment",
                    name: "aboutYou",
                    title: "Tell us about yourself",
                    placeHolder: "Kōrero mai mōu",
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "goals",
                    title:
                        "Tell us about your plans and aspirations once you have completed your studies",
                    placeHolder: "He aha ōu whāinga",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "comment",
                    name: "enhancementOfIwi",
                    title:
                        "How will you contribute to and enhance the mana of Ngāti Kuia?",
                    placeHolder:
                        "Ka pēhea koe e ehakanui i te mana o Ngāti Kuia?",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "dropdown",
                    name: "teReoLevel",
                    title: "Te Reo Māori Level",
                    choices: [
                        "None",
                        "Beginner",
                        "Intermediate",
                        "Fluent",
                        "Native",
                    ],
                    isRequired: true,
                    startWithNewLine: true,
                },
            ],
        },
        {
            name: "bankAccountCountry",
            title: "Bank Account - Pēke",
            questions: [
                {
                    name: "bankAccountCountry",
                    title:
                        "Do you have a New Zealand or an Overseas bank account?",
                    type: "radiogroup",
                    choices: ["Aotearoa", "Overseas"],
                    isRequired: true,
                },
            ],
        },
        {
            name: "overseasBankAccount",
            title: "Overseas Bank Account - Pēke Tāwahi",
            visibleIf: "{bankAccountCountry} = 'Overseas'",
            questions: [
                {
                    type: "text",
                    name: "overseasBankCountry",
                    title: "Country of Residence",
                    placeHolder: "Wāhi Noho",
                    startWithNewLine: false,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankSwift",
                    title: "SWIFT Code",
                    placeHolder: "Nama SWIFT",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankName",
                    title: "Account Name (must match your bank statement)",
                    placeHolder: "Ingoa Pēke",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankNumber",
                    title: "Account Number",
                    placeHolder: "Nama Pēke",
                    startWithNewLine: true,
                    isRequired: true,
                },
                {
                    type: "text",
                    name: "overseasBankAddress",
                    title: "Address",
                    placeHolder: "Address (must match your bank statement)",
                    startWithNewLine: true,
                    isRequired: true,
                },
            ],
        },
        {
            name: "bankDetails",
            title: "Bank Details - Tōu Pēke",
            visibleIf: "{bankAccountCountry} = 'Aotearoa'",
            elements: [
                {
                    type: BANK_ACCOUNT_KEY,
                    name: "bank",
                    title: "Name on Bank Account",
                    startWithNewLine: true,
                    isRequired: true,
                    validators: [
                        {
                            type: "expression",
                            expression: "validBankAccount({bank}) = true",
                            text:
                                "Please enter a bank account name and a valid number",
                        },
                    ],
                },
            ],
        },
    ];

    pages.push({
        name: "applicantDeclaration",
        title: "Applicant Declaration - Tauāki",
        questions: [
            {
                name: "honestyAcknowledgement",
                title:
                    "By checking this box, you declare that all information provided is true and correct.",
                type: "checkbox",
                choices: ["Āe"],
                isRequired: true,
            },
            {
                name: "seekfurtherInformationToAssistInApplicationProcess",
                title:
                    "By checking this box, you consent to Te Rūnanga o Ngāti Kuia Trust seeking further information from third parties to assist in processing this application.",
                type: "checkbox",
                choices: ["Āe"],
                isRequired: true,
            },
            {
                name: "advertisingAcknowledgement",
                title:
                    "I consent to Te Rūnanga o Ngāti Kuia Trust using my profile in Ngāti Kuia Iwi communications such as Maranga Mai and the Ngāti Kuia website and Facebook page if requested.",
                type: "checkbox",
                choices: ["Āe"],
                isRequired: true,
            },
        ],
    });

    return { pages };
};
