/* eslint-disable max-lines */
import { ADDRESS_FINDER_KEY } from "../../components/survey-widgets/address-finder-widget";
import { DATEPICKER_KEY } from "../../components/survey-widgets/datepicker-widget";
import { WHAKAPAPA_KEY } from "../../components/survey-widgets/whakapapa-widget";

// sp_pen_color
const ngahere = "rgba(0, 130, 72, 1)";
export async function createSurveyConfig({
    tupunaChoices,
}: {
    tupunaChoices: string[];
}) {
    return {
        pages: [
            {
                name: "personalDetails",
                title: "Personal Details",
                elements: [
                    {
                        type: "radiogroup",
                        name: "title",
                        title: "Title",
                        colCount: 0,
                        choices: ["Mrs", "Mr", "Miss", "Ms", "Mx", "Dr"],
                    },
                    {
                        type: "text",
                        name: "firstName",
                        title: "First Name(s)",
                        placeHolder: "Ingoa",
                        isRequired: true,
                    },
                    {
                        type: "text",
                        name: "surname",
                        title: "Surname",
                        placeHolder: "Ingoa Whānau",
                        isRequired: true,
                        startWithNewLine: false,
                    },
                    {
                        type: DATEPICKER_KEY,
                        inputType: "date",
                        name: "dob",
                        title: "Birthdate",
                        startWithNewLine: true,
                        isRequired: true,
                    },
                    {
                        type: "radiogroup",
                        name: "gender",
                        title: "Gender",
                        choices: ["Wahine", "Tāne", "Ira Weherua-kore"],
                        colCount: 0,
                        isRequired: true,
                        startWithNewLine: false,
                    },
                    {
                        name: "tupuna",
                        title: "Tupuna",
                        type: "dropdown",
                        isRequired: false,
                        choices: tupunaChoices,
                    },
                ],
            },
            {
                name: "address",
                title: "Address - Wāhi Noho",
                questions: [
                    {
                        title: "Address",
                        type: ADDRESS_FINDER_KEY,
                        name: "addressfinder",
                        isRequired: true,
                    },
                ],
            },
            {
                name: "contactDetails",
                title: "Contact Details",
                questions: [
                    {
                        title: "Mobile",
                        type: "text",
                        name: "cell",
                        startWithNewLine: false,
                    },
                    {
                        title: "Phone",
                        type: "text",
                        name: "phone",
                        startWithNewLine: false,
                    },
                    {
                        title: "Email",
                        type: "text",
                        name: "email",
                        validators: [
                            {
                                type: "email",
                            },
                        ],
                        startWithNewLine: true,
                    },
                ],
            },
            {
                name: "occupation",
                title: "Occupation and Education",
                questions: [
                    {
                        type: "dropdown",
                        name: "education",
                        title: "Education",
                        choices: [
                            "Apprenticeship",
                            "Tertiary",
                            "Degree",
                            "Masters",
                            "Trade",
                            "Other",
                        ],
                    },
                    {
                        type: "text",
                        name: "details",
                        title: "Details",
                        startWithNewLine: false,
                    },
                    {
                        type: "text",
                        name: "occupation",
                        title: "Occupation",
                        startWithNewLine: true,
                    },
                ],
            },
            {
                name: "whakapapa",
                title: "Whakapapa to Ngāti Kuia",
                questions: [
                    {
                        title: "Whakapapa",
                        type: WHAKAPAPA_KEY,
                        name: "whakapapa",
                        isRequired: true,
                    },
                ],
            },
            {
                name: "privacy",
                title: "Declaration & Privacy",
                questions: [
                    {
                        type: "html",
                        html: `I acknowledge that the information provided in this form is subject to the Privacy Act 1993; and that by signing this form, I agree that the Te Rūnanga o 
                        Ngāti Kuia Trust and subsidiaries may use this information:<br/><br/>
                        <ul>
                         <li>To maintain the Iwi Register/Database and Voting Roll</li>
                         <li>
                          For verification to the Office of Treaty Settlements for Ngāti Kuia Historical Claims
                         </li>
                         <li>For verification to Te Ohu Kaimoana for Ngāti Kuia Fisheries Settlement</li>
                         <li>And any other purpose Te Rūnanga o Ngati Kuia Trust considers reasonable</li>
                        </ul>`,
                    },
                    {
                        name: "privacyAcknowledgement",
                        title:
                            "I understand that the information I provide is subject to the Privacy Act 1993",
                        type: "checkbox",
                        choices: ["Āe"],
                        isRequired: true,
                    },
                ],
            },
            {
                name: "privacy",
                title: "Declaration & Privacy",
                questions: [
                    {
                        type: "html",
                        html: `I also consent to receiving private notices relating to general
                            hui and postal ballot papers so that I may vote on
                            elections, constitutional amendments, conversion or
                            disposal of settlement quota and such when required. The
                            notice and ballot papers will be sent to the address
                            provided on this form.`,
                    },
                    {
                        name: "noticeAndBallotPapersAcknowledgement",
                        title:
                            "I consent to receiving notices and ballot papers at the address provided",
                        type: "checkbox",
                        choices: ["Āe"],
                        isRequired: true,
                    },
                    {
                        name: "honestyAcknowledgement",
                        title:
                            "I declare that all information provided is true and correct",
                        type: "checkbox",
                        choices: ["Āe"],
                        isRequired: true,
                    },
                    {
                        name: "signed",
                        type: "signaturepad",
                        title: "Please sign your name on the signature pad",
                        penColor: ngahere,
                        width: 359,
                        isRequired: true,
                    },
                ],
            },
        ],
    };
}
